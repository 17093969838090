import {Injectable, NgZone} from '@angular/core'
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {catchError} from 'rxjs/operators'
import {throwError} from 'rxjs'
import {ConfigService} from '../services/config.service'
import {Router} from '@angular/router'
import {LOGIN_ROUTE_PATH} from './data-types'


/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private configService: ConfigService,
    private ngZone: NgZone,
    private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status > 400 && error.status < 404) {
          this.router.navigate([LOGIN_ROUTE_PATH], {
            skipLocationChange: true,
            queryParams: {reason: error.status}
          }).then()
          this.configService.resetToken()
        }
        return throwError(() => new Error('test'))
      })
    )
  }
}

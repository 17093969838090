<div class="spb-holder">
  <div class="mat-typography">
    <spb-header></spb-header>
  </div>
  <body class="mat-typography">
  <router-outlet></router-outlet>
  </body>
  <div class="spb-filler"></div>
  <spb-footer
    [token]="(configService.configState$ | async)?.token || ''"
    [license]="true"
    [version]="version"
    copyrightYears="2022-2023"
    (logoutUser)="logout($event)"
  ></spb-footer>
</div>

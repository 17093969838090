import {Component, NgZone, OnDestroy, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService, SpbConfiguration} from '../../services/config.service'
import {catchError, EMPTY, Subscription, switchMap} from 'rxjs'
import {filter} from 'rxjs/operators'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {IS_RT_ADMIN_ROLE} from '../../application/data-types'
import {environment} from '../../../environments/environment'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public bankIdUrl = environment.authServiceUrl

  /**
   * The current user.
   */
  public user: SparbankenUser = {itemId: '', roles: [], sId: '', sub: '', name: ''} as any

  /**
   * Tells if admin functions are available.
   */
  public isRtAdmin = false

  /**
   * Hide the menu etc. if not logged in at all.
   */
  public ready = false

  /**
   * Hide the menu from non
   */
  public isRtUser = false

  /**
   * Holds the subscription of config and currentUser.
   */
  private config$ = new Subscription()
  private currentUser$ = new Subscription()

  constructor(
    public configService: ConfigService,
    private router: Router,
    private ngZone: NgZone,
    private singleSignOnService: SingleSignOnService
  ) {
  }

  public ngOnInit(): void {
    this.config$ = this.configService.configState$
      .pipe(
        filter((config: SpbConfiguration) => config.ready)
      )
      .subscribe({
        next: (config: SpbConfiguration) => {
          this.ready = config.ready
          this.isRtUser = config.rtUser
        }
      })

    /**
     * Get the current user and give admin rights if the user has the correct role
     */
    this.currentUser$ = this.configService.currentUser$
      .subscribe({
        next: (user: SparbankenUser) => {
          if (user.roles) {
            this.user = user
            this.isRtAdmin = user.roles.indexOf(IS_RT_ADMIN_ROLE) !== -1
          }
        }
      })

    this.singleSignOnService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          this.logout()
          return EMPTY
        })
      )
      .subscribe(
        {next: (accessToken: string) => this.configService.setToken(accessToken)})
  }

  public ngOnDestroy(): void {
    this.config$.unsubscribe()
    this.currentUser$.unsubscribe()
  }

  public logout(): void {
    this.singleSignOnService.deleteToken(this.bankIdUrl).pipe(
      switchMap(() => this.configService.resetToken()))
      .subscribe({
          next: () => this.ngZone.run(() => this.router.navigate(['login']))
      })
  }
}

import {Component} from '@angular/core'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'
import {switchMap} from 'rxjs/operators'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../environments/environment'
import {Router} from '@angular/router'
@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public version = version.version
  constructor(
    private router: Router,
    private singleSignOnService: SingleSignOnService,
    public configService: ConfigService) {}

  public logout(logout: boolean): void {
    if (logout) {
      this.singleSignOnService.deleteToken(environment.authServiceUrl).pipe(
        switchMap(() => this.configService.resetToken()))
        .subscribe({
          next: () => this.router.navigate(['login'])
        })
    }
  }

}
